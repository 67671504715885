import React, {useEffect, useState} from 'react';
import {Modal, Button, Row, Col, FormLabel, FormControl, Form} from 'react-bootstrap';
import {initUser, userInterface} from "../../Interfaces/User";
import moment from "moment";
import {toast} from "react-toastify";
import {validateEmail} from "../../SharedFunctions/SharedFunctions";

interface sharedUserModalProps {
    show: boolean;
    handleClose: () => void;
    user: userInterface
    createUser: (user: userInterface) => void;
}

const getInitialDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().substring(0, 10);
}

const UserModal = ({show, handleClose, user, createUser}: sharedUserModalProps) => {

    const initDate = getInitialDate();
    const [birthDate, setBirthDate] = useState(initDate);
    const [userForm, setUserForm] = useState(initUser);

    useEffect(() => {
        setUserForm(user)
    }, [user]);

    const isMoreThan18 = (birthDate: string) => {
        const date18YearsAgo = new Date();
        date18YearsAgo.setFullYear(date18YearsAgo.getFullYear() - 18);
        return new Date(birthDate) <= date18YearsAgo;
    };

    const validateForm = () => {
        return !(userForm.name.length > 1 &&
            userForm.surname.length > 1 &&
            userForm.phone.length > 1 &&
            userForm.identification.length > 1 &&
            validateEmail(userForm.email) &&
            isMoreThan18(moment(userForm.birthDate).format('YYYY-MM-DD')));
    }

    const handleSubmit = () => {
        createUser(userForm);
        setUserForm(initUser);
        setBirthDate(initDate)
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Añadir Paciente</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Row className="mt-4">
                        <Col md={6}>
                            <FormLabel>Nombre:</FormLabel>
                            <FormControl
                                type="text"
                                name="userName"
                                onChange={(value) => {
                                    setUserForm({
                                        ...userForm,
                                        name: value.target.value
                                    })
                                }}
                                value={userForm.name}
                                className="form-group"
                            />
                        </Col>
                        <Col md={6}>
                            <FormLabel>Apellido:</FormLabel>
                            <FormControl
                                type="text"
                                name="userSurname"
                                onChange={(value) => {
                                    setUserForm({
                                        ...userForm,
                                        surname: value.target.value
                                    })
                                }}
                                value={userForm.surname}
                                className="form-group"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={6}>
                            <FormLabel>Teléfono:</FormLabel>
                            <FormControl
                                type="text"
                                name="userPhone"
                                onChange={(value) => {
                                    setUserForm({
                                        ...userForm,
                                        phone: value.target.value
                                    })
                                }}
                                value={userForm.phone}
                                className="form-group"
                            />
                        </Col>
                        <Col md={6}>
                            <FormLabel>Email:</FormLabel>
                            <FormControl
                                type="text"
                                name="userEmail"
                                onChange={(value) => {
                                    setUserForm({
                                        ...userForm,
                                        email: value.target.value
                                    });
                                }}
                                onBlur={() => {
                                    if (!validateEmail(userForm.email)) {
                                        setUserForm({
                                            ...userForm,
                                            email: ''
                                        });
                                        toast.error("Correo electrónico no válido.");
                                    }
                                }}
                                value={userForm.email}
                                className="form-group"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-4">
                        <Col md={6}>
                            <FormLabel>Fecha de nacimiento:</FormLabel>
                            <FormControl
                                type="date"
                                name="userBirthdate"
                                onChange={(e) => {
                                    const fechaNacimiento = e.target.value;
                                    if (isMoreThan18(fechaNacimiento)) {
                                        setBirthDate(e.target.value)
                                        setUserForm({
                                            ...userForm,
                                            birthDate: new Date(e.target.value)
                                        });
                                    } else {
                                        setBirthDate(initDate)
                                        toast.error("debe ser mayor de 18 años")
                                    }
                                }}
                                value={birthDate}
                                className="form-group"
                            />
                        </Col>

                        <Col md={6}>
                            <FormLabel>Identificación:</FormLabel>
                            <FormControl
                                type="text"
                                name="userIdentification"
                                onChange={(value) => {
                                    setUserForm({
                                        ...userForm,
                                        identification: value.target.value
                                    })
                                }}
                                value={userForm.identification}
                                className="form-group"
                            />
                        </Col>
                    </Row>

                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Cerrar
                </Button>
                <Button
                    variant="primary"
                    disabled={validateForm()}
                    onClick={handleSubmit}
                >
                    Crear paciente
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserModal;
