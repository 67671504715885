import React, {ChangeEvent} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const validateEmail = (email: string) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
};

interface infoToCustomTextField {
    label: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    clearValue: () => void;
}
export const CustomTextField = ({label, onChange, value, clearValue}: infoToCustomTextField) => {
    return (
        <TextField
            label={label}
            value={value}
            onChange={onChange}
            fullWidth
            InputProps={{
                endAdornment: (
                    value && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    clearValue()
                                }}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                ),
            }}
            sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.50)',
                    },
                },
                '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                        color: '#4b4b4b',
                    },
                },
            }}
        >
        </TextField>
    );
};


