import React from 'react';
import './css/App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import LoginForm from "./Components/LoginForm";
import {ApiHook} from "./ApiHook/ApiHook";
import Admin from "./Components/Admin";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {

    const {isTokenValid } = ApiHook();

    return (
        <div className="App">
            <header className="App-header">

                {isTokenValid ? (
                    <Admin />
                ) : (
                    <LoginForm/>
                )}

                <ToastContainer />

            </header>
        </div>
    );
}

export default App;
