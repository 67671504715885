
export interface ISearchFilter {
    nameSurname: string;
    email: string;
    phone: string;
    identity: string;
}

export const initSearchFilter: ISearchFilter = {
    nameSurname: "",
    phone: "",
    email: "",
    identity: "",
}
