import React, {Fragment, useEffect, useState} from 'react';
import {ApiHook} from "../../ApiHook/ApiHook";
import {Button, Pagination, Table} from "react-bootstrap";
import AdminModal from "./AdminModal";
import {initAdmin} from "../../Interfaces/Admin";
import {Grid} from "@mui/material";
import {CustomTextField} from "../../SharedFunctions/SharedFunctions";
import {initSearchFilter} from "../../Interfaces/SearchFilter";

const AdminList = () => {

    const {usersAdminList, getAdminUsers, createAdmin, deleteAdmin} = ApiHook();

    const [searchFilter, setSearchFilter] = useState(initSearchFilter);
    const [showModal, setShowModal] = useState(false);
    const [adminForm, setAdminForm] = useState(initAdmin);

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 100;
    const indexOfLastAdmin = currentPage * usersPerPage;
    const indexOfFirstAdmin = indexOfLastAdmin - usersPerPage;
    const currentAdmins = usersAdminList.slice(indexOfFirstAdmin, indexOfLastAdmin);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(usersAdminList.length / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getAdminUsers(searchFilter)
        }, 400);
        return () => clearTimeout(timer);
    }, [searchFilter])

    return (

        <Fragment>

            <h3 className="mb-4" style={{color: 'gray'}}>
                ADMINISTRADORES
            </h3>

            <Grid container spacing={2} sx={{mb: 1}}>
                <Grid item xs={6}>
                    <CustomTextField
                        label="Nombre / Apellido"
                        value={searchFilter.nameSurname}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                nameSurname: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                nameSurname: ""
                            })
                        }}
                    />

                </Grid>
                <Grid item xs={6}>
                    <CustomTextField
                        label="Email"
                        value={searchFilter.email}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                email: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                email: ""
                            })
                        }}
                    />
                </Grid>
            </Grid>

            <div className='table-responsive' style={{maxHeight: 600, marginTop: 10}}>
                <Table className='table table-striped align-middle' style={{width: 1400}}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Email</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentAdmins.map((admin, index) => (
                        <tr key={index}>
                            <td>{admin.name}</td>
                            <td>{admin.surname}</td>
                            <td>{admin.email}</td>
                            <td>
                                {admin.name !== 'SuperAdmin' &&
                                    <Button
                                        style={{marginRight: '5px'}}
                                        variant="warning"
                                        size="sm"
                                        onClick={async () => {
                                            if (window.confirm('¿Estás seguro de que deseas eliminar este administrador?')) {
                                                await deleteAdmin(admin);
                                            }
                                        }}
                                    >
                                        Eliminar
                                    </Button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            <Pagination>
                {pageNumbers.map(number => (
                    <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => paginate(number)}
                    >
                        {number}
                    </Pagination.Item>
                ))}
            </Pagination>

            <Button
                className="ms-2"
                style={{marginRight: '5px'}}
                variant="success"
                size="sm"
                onClick={() => {
                    setAdminForm(initAdmin)
                    setShowModal(true);
                }}
            >
                Añadir Administrador
            </Button>


            <AdminModal
                show={showModal}
                admin={adminForm}
                createAdmin={async (user) => {
                    await createAdmin(user)
                }}
                handleClose={() => {
                    setShowModal(false);
                }}
            />

        </Fragment>

    );
};

export default AdminList;


