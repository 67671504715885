import React, {Fragment, useEffect, useState} from 'react';
import {Button, Pagination, Table} from 'react-bootstrap';
import {ApiHook} from "../../ApiHook/ApiHook";
import {initUser} from "../../Interfaces/User";
import UserModal from "./UserModal";
import moment from "moment";
import {Grid} from "@mui/material";
import {initSearchFilter} from "../../Interfaces/SearchFilter";
import {CustomTextField} from "../../SharedFunctions/SharedFunctions";

const UsersList = () => {

    const {usersList, getUsers, createUser, redirectToDoctiviOTAC} = ApiHook();

    const [searchFilter, setSearchFilter] = useState(initSearchFilter);
    const [showModal, setShowModal] = useState(false);
    const [userForm, setUserForm] = useState(initUser);

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 100;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = usersList.slice(indexOfFirstUser, indexOfLastUser);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(usersList.length / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getUsers(searchFilter);
        }, 400);
        return () => clearTimeout(timer);
    }, [searchFilter])

    return (

        <Fragment>

            <h3 className="mb-4" style={{color: 'gray'}}>
                PACIENTES
            </h3>

            <Grid container spacing={2} sx={{mb: 1}}>
                <Grid item xs={3}>
                    <CustomTextField
                        label="Nombre / Apellido"
                        value={searchFilter.nameSurname}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                nameSurname: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                nameSurname: ""
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField
                        label="Teléfono"
                        value={searchFilter.phone}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                phone: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                phone: ""
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField
                        label="Email"
                        value={searchFilter.email}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                email: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                email: ""
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomTextField
                        label="Identificación"
                        value={searchFilter.identity}
                        onChange={(event) => {
                            setSearchFilter({
                                ...searchFilter,
                                identity: event.target.value
                            })
                        }}
                        clearValue={() => {
                            setSearchFilter({
                                ...searchFilter,
                                identity: ""
                            })
                        }}
                    />
                </Grid>
            </Grid>


            <div>
                <div className='table-responsive' style={{maxHeight: 600, marginTop: 10}}>
                    <Table className='table table-striped align-middle' style={{width: 1400}}>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Fecha Nacimiento</th>
                            <th>Identificación</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentUsers.map((user, index) => (
                            <tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.surname}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>{moment(user.birthDate).format('DD / MM / YYYY')}</td>
                                <td>{user.identification}</td>
                                <td>
                                    <Button
                                        style={{marginRight: '5px'}}
                                        variant="dark"
                                        size="sm"
                                        onClick={() => {
                                            if (redirectToDoctiviOTAC) {
                                                redirectToDoctiviOTAC(user)
                                            }
                                        }}
                                    >
                                        Ir a Doctivi
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>

                <Pagination>
                    {pageNumbers.map(number => (
                        <Pagination.Item
                            key={number}
                            active={number === currentPage}
                            onClick={() => paginate(number)}
                        >
                            {number}
                        </Pagination.Item>
                    ))}
                </Pagination>

            </div>

            <Button
                className="ms-2"
                style={{marginRight: '5px'}}
                variant="success"
                size="sm"
                onClick={() => {
                    setUserForm(initUser)
                    setShowModal(true);
                }}
            >
                Añadir Paciente
            </Button>

            <UserModal
                show={showModal}
                user={userForm}
                createUser={async (user) => {
                    await createUser(user)
                }}
                handleClose={() => {
                    setShowModal(false);
                }}
            />

        </Fragment>

    );
};

export default UsersList;


