import React, {Fragment, useContext, useState} from 'react';
import {Button, Spinner} from 'react-bootstrap';
import UsersList from "./Users/UsersList";
import AdminList from './UsersAdmin/AdminList';
import doctiviLogo from "../assets/doctiviLogo.png";
import {AuthContext} from "../AppContext/AppContext";

const Admin = () => {

    const [whatShow, setWhatShow] = useState<"ADMIN" | "PATIENTS">('PATIENTS');
    const {isAppLoading} = useContext(AuthContext);

    return (

        <Fragment>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '30%', height: '100%' }}>
                <img
                    src={doctiviLogo}
                    alt="Descripción de la imagen"
                    style={{
                        marginTop: 50,
                        width: 200,
                        marginBottom: 50,
                    }}
                />
                {isAppLoading && (
                    <div style={{ position: 'absolute', right: 0, height: 30, color: "gray" }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                )}
            </div>

            <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginBottom: '30px'}}>
                <Button
                    className="ms-2"
                    size="lg"
                    style={{
                        backgroundColor: whatShow === 'PATIENTS' ? '#0d6efd' : 'gray',
                        borderColor: whatShow === 'PATIENTS' ? '#0d6efd' : 'gray',
                        width: 500,
                        marginRight: '25px'
                    }}
                    onClick={() => {
                        setWhatShow('PATIENTS')
                    }}
                >
                    Pacientes
                </Button>
                <Button
                    className="ms-2"
                    size="lg"
                    style={{
                        backgroundColor: whatShow === 'ADMIN' ? '#0d6efd' : 'gray',
                        borderColor: whatShow === 'ADMIN' ? '#0d6efd' : 'gray',
                        width: 500,
                    }}
                    onClick={() => {
                        setWhatShow("ADMIN")
                    }}
                >
                    Administradores
                </Button>
            </div>

            <div className='mt-3'>
                {whatShow === 'ADMIN' &&
                    <AdminList/>
                }

                {whatShow === 'PATIENTS' &&
                    <UsersList/>
                }
            </div>

        </Fragment>

    );
};

export default Admin;


