export interface userInterface {
    _id: string | null,
    name: string;
    surname: string;
    phone: string;
    email: string;
    birthDate: Date;
    identification: string;
}

export const initUser: userInterface = {
    _id: null,
    name: "",
    surname: "",
    phone: "",
    email: "",
    birthDate: new Date(),
    identification: ""
}
