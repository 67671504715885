import React, {Fragment, useState} from 'react';
import {Col, FormControl, FormLabel, Row} from 'react-bootstrap';
import doctiviLogo from '../assets/doctiviLogo.png';
import {ApiHook} from "../ApiHook/ApiHook";

const LoginForm = () => {

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const {doLogin} = ApiHook();

    return (

        <Fragment>

            <img
                src={doctiviLogo} alt="Descripción de la imagen"
                style={{
                    marginTop: 200,
                    width: 300,
                    marginBottom: 80,
                }}
            />

            <form
                style={{color: '#444343'}}
                onSubmit={async (e) => {
                    e.preventDefault();
                    await doLogin(user, password)
                }}
            >

                <Row className="mt-5">
                    <Col md={12}>
                        <FormLabel>Usuario:</FormLabel>
                        <FormControl
                            type="text"
                            name="user_identifier"
                            onChange={(event) => {
                                const value = event.target.value
                                setUser(value)
                            }}
                            value={user} className="form-group"
                        />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={12}>
                        <FormLabel>Contraseña:</FormLabel>
                        <FormControl
                            type="password"
                            name="passwordForLogin"
                            onChange={(event) => {
                                const value = event.target.value
                                setPassword(value)
                            }}
                            value={password} className="form-group"
                        />
                    </Col>
                </Row>

                <Row className="mt-5">
                    <button
                        className="btn btn-warning mb-1 mt-5"
                        /*onClick={async () => {
                            await doLogin(user, password)
                        }}*/
                    >
                        Iniciar sesión
                    </button>
                </Row>

            </form>

        </Fragment>
    );
};

export default LoginForm;


