import React, {useEffect, useState} from 'react';
import {Modal, Button, Row, Col, FormLabel, FormControl, Form} from 'react-bootstrap';
import {adminInterface, initAdmin} from "../../Interfaces/Admin";
import {toast} from "react-toastify";
import {validateEmail} from "../../SharedFunctions/SharedFunctions";

interface sharedAdminModalProps {
    show: boolean;
    handleClose: () => void;
    admin: adminInterface
    createAdmin: (admin: adminInterface) => void;
}

const AdminModal = ({show, handleClose, admin, createAdmin}: sharedAdminModalProps) => {

    const [adminForm, setAdminForm] = useState(initAdmin);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    useEffect(() => {
        setAdminForm(admin)
    }, [admin]);

    useEffect(() => {
        if (password1 === password2) {
            setAdminForm({
                ...adminForm,
                password: password1
            })
        }
    }, [adminForm, password1, password2]);

    const validateForm = () => {
        return !(adminForm.name.length > 1 &&
            adminForm.surname.length > 1 &&
            password1 === password2 &&
            adminForm.password.length > 1 &&
            adminForm.password === password2 &&
            validateEmail(adminForm.email));
    }

    const handleSubmit = () => {
        createAdmin(adminForm);
        setAdminForm(initAdmin);
        setPassword1("");
        setPassword2("");
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Añadir Administrador</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Row className="mt-4">
                        <Col md={6}>
                            <FormLabel>Nombre:</FormLabel>
                            <FormControl
                                type="text"
                                name="userName"
                                onChange={(value) => {
                                    setAdminForm({
                                        ...adminForm,
                                        name: value.target.value
                                    })
                                }}
                                value={adminForm.name}
                                className="form-group"
                            />
                        </Col>
                        <Col md={6}>
                            <FormLabel>Apellido:</FormLabel>
                            <FormControl
                                type="text"
                                name="userSurname"
                                onChange={(value) => {
                                    setAdminForm({
                                        ...adminForm,
                                        surname: value.target.value
                                    })
                                }}
                                value={adminForm.surname}
                                className="form-group"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={12}>
                            <FormLabel>Email:</FormLabel>
                            <FormControl
                                type="text"
                                name="userEmail"
                                onChange={(value) => {
                                    setAdminForm({
                                        ...adminForm,
                                        email: value.target.value
                                    })
                                }}
                                onBlur={() => {
                                    if (!validateEmail(adminForm.email)) {
                                        setAdminForm({
                                            ...adminForm,
                                            email: ''
                                        })
                                        toast.error("Correo electrónico no válido.");
                                    }
                                }}
                                value={adminForm.email}
                                className="form-group"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-4">
                        <Col md={6}>
                            <FormLabel>Contraseña:</FormLabel>
                            <FormControl
                                type="password"
                                name="userPass1"
                                onChange={(value) => {
                                    setPassword1(value.target.value)
                                }}
                                value={password1}
                                className="form-group"
                            />
                        </Col>

                        <Col md={6}>
                            <FormLabel>Confirme contraseña:</FormLabel>
                            <FormControl
                                type="password"
                                name="userPass2"
                                onChange={(value) => {
                                    setPassword2(value.target.value)
                                }}
                                value={password2}
                                className="form-group"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{span: 6, offset: 3}}>
                            <div style={{
                                color: '#FE6767',
                                textAlign: 'center',
                                fontSize: '14px',
                                height: '20px',
                                opacity: password1 !== password2 ? 1 : 0,
                                transition: 'opacity 0.3s'
                            }}>
                                Las contraseñas no coinciden.
                            </div>
                        </Col>
                    </Row>

                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Cerrar
                </Button>
                <Button
                    variant="primary"
                    disabled={validateForm()}
                    onClick={handleSubmit}
                >
                    Crear administrador
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AdminModal;
