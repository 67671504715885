import {useContext, useEffect, useState} from "react";
import axios, {AxiosRequestConfig} from 'axios'
import {userInterface} from "../Interfaces/User";
import {AuthContext} from "../AppContext/AppContext";
import {toast} from 'react-toastify';
import {adminInterface} from "../Interfaces/Admin";
import {initSearchFilter, ISearchFilter} from "../Interfaces/SearchFilter";

let apiUrl: string = ""
if (process.env.REACT_APP_BACKEND_URL) {
    apiUrl = process.env.REACT_APP_BACKEND_URL
}

export const ApiHook = () => {

    const [isTokenValid, setIsTokenValid] = useState(false)

    const {token, setToken} = useContext(AuthContext);
    const {usersAdminList, setUsersAdminList} = useContext(AuthContext);
    const {usersList, setUsersList} = useContext(AuthContext);
    const {isAppLoading, setIsAppLoading} = useContext(AuthContext);

    useEffect(() => {
        if (token && token.length > 0) {
            setIsTokenValid(true)
        } else {
            setIsTokenValid(false)
        }
    }, [token])

    const doLogin = async (userEmail: string, password: string) => {
        setIsAppLoading(true)
        if (userEmail.length > 0 && password.length > 0) {
            const config: AxiosRequestConfig = {
                method: 'POST',
                url: apiUrl + "/login",
                data: {userEmail, password}
            };
            try {
                const response = await axios.request(config);
                if (response.data.token) {
                    setToken(response.data.token)
                }
            } catch (error: any) {
                toast.error("Error al iniciar sesión. Email y/o contraseña incorrecto");
                console.error('Error en doLogin:', error.message);
            }
        }else{
            toast.error("Debe insertar email y contraseña");
        }
        setIsAppLoading(false)
    }

    const getAdminUsers = async (searchFilter: ISearchFilter) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/admin/getAdmins",
            data: {searchFilter: searchFilter}
        };
        try {
            const response = await axios.request(config);
            if (response.data && response.data.length > 0) {
                setUsersAdminList(response.data)
            }
        } catch (error: any) {
            console.error('Error en getAdminUsers:', error.message);
        }
        setIsAppLoading(false)
    }

    const getUsers = async (searchFilter: ISearchFilter) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/user/getUsers",
            data: {searchFilter: searchFilter}
        };
        try {
            const response = await axios.request(config);
            if (response.data) {
                if (response.data.length > 0) {
                    setUsersList(response.data)
                }else{
                    setUsersList([])
                }
            }
        } catch (error: any) {
            console.error('Error en getUsers:', error.message);
        }
        setIsAppLoading(false)
    }

    const createUser = async (userToAdd: userInterface) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/user/createUser",
            data: {user: userToAdd}
        };
        try {
            const response = await axios.request(config);
            await getAdminUsers(initSearchFilter)
            await getUsers(initSearchFilter)
            toast.success("Usuario creado");
        } catch (error: any) {
            toast.error("Error en crear usuario");
            console.error('Error en createUser:', error.message);
        }
        setIsAppLoading(false)
    }

    const createAdmin = async (adminToAdd: adminInterface) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/admin/createAdmin",
            data: {admin: adminToAdd}
        };
        try {
            const response = await axios.request(config);
            await getAdminUsers(initSearchFilter)
            await getUsers(initSearchFilter)
            toast.success("Administrador creado");
        } catch (error: any) {
            toast.error("Error en crear administrador");
            console.error('Error en createUser:', error.message);
        }
        setIsAppLoading(false)
    }

    const deleteAdmin = async (adminToDelete: adminInterface) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/admin/deleteAdmin",
            data: {admin: adminToDelete}
        };
        try {
            const response = await axios.request(config);
            await getAdminUsers(initSearchFilter)
            await getUsers(initSearchFilter)
            toast.success("Administrador eliminado");
        } catch (error: any) {
            toast.error("Error en eliminar administrador");
            console.error('Error en deleteAdmin:', error.message);
        }
        setIsAppLoading(false)
    }

    const redirectToDoctiviOTAC = async (user: userInterface) => {
        setIsAppLoading(true)
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            url: apiUrl + "/doctivi/getOtacUrl",
            data: {user: user}
        };
        try {
            const response = await axios.request(config);
            window.open(response.data, '_blank');
        } catch (error: any) {
            console.error('Error en get OTAC:', error.message);
        }
        setIsAppLoading(false)
    }


    return {
        isAppLoading,
        isTokenValid,
        usersAdminList,
        usersList,
        doLogin,
        getAdminUsers,
        getUsers,
        createUser,
        createAdmin,
        deleteAdmin,
        redirectToDoctiviOTAC
    }
}
