import React from 'react';
import ReactDOM from 'react-dom/client';
import './css//index.css';
import App from './App';
import {AuthProvider} from './AppContext/AppContext';
import axios from "axios";


/*---------------------- AXIOS INTERCEPTOR -----------------------*/
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = false;

//request Interceptor
axios.interceptors.request.use(
    config => {
        console.log("axios interceptor request")
        /*const token = localStorage.getItem('accessToken');
        if (config.headers) {
            config.headers['Authorization'] = 'Bearer ';
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
        }*/
        return config;
    },
    error => {
        return Promise.reject(error)
    }
);

//response Interceptor
axios.interceptors.response.use((response) => {
        return response
    }, function (error) {
        const {status} = error.response;
        if (error.message === 'Network Error') { //** ERROR NETWORK **//
            window.location.reload();
        }
        if (status === 401) {//NOT AUTHORIZED
            window.location.reload();
        }
        if (status === 403) {//FORBIDDEN
            //window.location.reload();
        }
        return Promise.reject(error);
    }
);
/*---------------------- AXIOS INTERCEPTOR -----------------------*/


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </React.StrictMode>
);

