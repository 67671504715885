export interface adminInterface {
    _id: string | null,
    name: string;
    surname: string;
    email: string;
    password: string;
}

export const initAdmin: adminInterface = {
    _id: null,
    name: "",
    surname: "",
    email: "",
    password: "",
}
