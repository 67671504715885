import React, { createContext, useState, ReactNode } from 'react';
import {userInterface} from "../Interfaces/User";
import {adminInterface} from "../Interfaces/Admin";

interface AppContextType {

    token: string;
    setToken: (token: string) => void;

    usersList: userInterface[]
    setUsersList: (users: userInterface[]) => void;

    usersAdminList: adminInterface[]
    setUsersAdminList: (admins: adminInterface[]) => void;

    isAppLoading: boolean
    setIsAppLoading: (isLoading: boolean) => void;
}

const defaultValue: AppContextType = {
    token: '',
    setToken: () => {},

    usersList: [],
    setUsersList: () => {},

    usersAdminList: [],
    setUsersAdminList: () => {},

    isAppLoading: false,
    setIsAppLoading: () => {},
};

export const AuthContext = createContext<AppContextType>(defaultValue);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const [token, setToken] = useState<string>('');
    const [usersList, setUsersList] = useState<userInterface[]>([]);
    const [usersAdminList, setUsersAdminList] = useState<adminInterface[]>([]);
    const [isAppLoading, setIsAppLoading] = useState<boolean>(false);

    return (
        <AuthContext.Provider value={{ token, setToken, usersList, setUsersList, usersAdminList, setUsersAdminList, isAppLoading, setIsAppLoading}}>
            {children}
        </AuthContext.Provider>
    );
};
